import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Flag from 'react-world-flags';
import Flip from 'react-reveal/Flip';

import Layout from '../components/layout';
import SEO from '../components/seo';
import programmation from '../pdf/PROG-CINELANGUES-ESPAGNOL.pdf';

export const pageQuery = graphql`
  query {
    films: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { glob: "**/content/films/**/*.md" }
        frontmatter: { category: { eq: "Espagnol" } }
      }
      sort: { order: DESC, fields: [frontmatter___time] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          id
          html
          excerpt
          frontmatter {
            title
            meta
            time
            classe
            affiche {
              childImageSharp {
                fluid(quality: 100, maxWidth: 400, maxHeight: 540) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`;

const EspagnolPage = ({ data }) => (
  <Layout>
    <SEO
      title="Films Espagnol 2022 - Cinelangues"
      description="Retrouvez tous nos films espagnols."
      pathname="/espagnol-les-films-cinelangues/"
    />
    <div className="page">
      <div className="container jumbotron">
        <div className="row">
          <div className="col-sm-12 col-md-8 header-content my-auto mt-5">
            <h1 className="ml-2">
              <Flag code="es" height="30" className="ml-2 mr-2" />
              Films en espagnol
            </h1>
          </div>
          <div className="col-sm-12 col-md-4 my-auto text-right">
            <a
              type="button"
              className="mr-2 mt-1 ml-2"
              data-toggle="modal"
              data-target="#exampleModal"
              href="#"
            >
              <i className="fa fa-edit"></i>&nbsp;&nbsp;Présentation
            </a>
            <a
              href={programmation}
              target="_blank"
              className="mt-1"
              rel="norefer noopener"
            >
              <i className="fa fa-download"></i>&nbsp;&nbsp;Programmation PDF
            </a>
          </div>
        </div>
        <div
          className="modal fade"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title mt-0">
                  Programmation Espagnol 2023-2024
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p className="small">
                  <strong>Nous sommes heureux de vous proposer pour cette année Cinélangues 2024-2025 une nouvelle programmation composée de trois nouveaux films de grande qualité qui témoignent de la vitalité cinématographique espagnole et latinoaméricaine et de reprises ou continuation de films que vous avez su apprécier les années précédentes.  Ces films vous inviteront à parcourir sucessivement l’Espagne, et quatre pays d’Amérique latine (Argentine, Chili, Pérou, Colombie). Ils vous permettront d’aborder en classe de nombreux thèmes relatifs à ces pays, qu’ils soient d’ordre historiques, sociaux, politiques ou culturels.</strong>
                </p>
                <p className="small">Comme l’année dernière nous poursuivrons notre programmation “à la carte” de films sélectionnés en fonction de leur intérêt cinématographique bien sûr mais aussi de la possibilité qu’ils offrent d’enrichir l’étude des axes culturels au collège et au lycée. Plus de programmation trimestrielle donc mais une totale liberté pour construire votre propre programmation en fonction de votre calendrier.</p>
                <p className="small">Un des grands thèmes qui traverse cette programmation 2024-2025 est celui de l’enfance, qu’elle soit vécue dans un cadre familial (20000 espèces d’abeilles ou Un mundo para Julius) ou hors cadre familial (Mis hermanos). La quête identitaire des protagonistes, la prise de conscience du regard des autres, la difficulté à être que l’on soit issu de classes favorisées ou non sont les fils rouges de tous ces films.</p>
                <p className="small">La famille et le passage vers l’adolescence, moment de la vie où les changements sont vertigineux, sont d’autres thèmes récurrents. Le film argentin Camila sortira ce soir de Inés Maria Barrionuevo invite le spectateur à suivre Camila dans ses conflits intérieurs et son cheminement vers la découverte de soi et du monde qui l’entoure. Dans L’oubli que nous serons, alors que la magie d’un cadre familial rassurant s’estompe c’est  la violence du monde et des rapports sociaux qui s’infiltrent dans l’intimité familiale, bousculant de manière dramatique l’équilibre de chacun.</p>
                <p className="small">Mais cette programmation permet d’évoquer aussi, avec des films comme Les repentis, d’Iciar Bolain et Chili 1976, de <a href="https://www.allocine.fr/personne/fichepersonne_gen_cpersonne=101703.html" target="_blank">Manuela Martelli</a> des périodes historiques qui ont marqué l’Espagne et le Chili et qui, encore aujourd’hui, laissent des traces dans la société civile de ces pays. Les repentis, revient sur le problème basque et s’interroge avec courage sur la possibilité du pardon et de la réconciliation. Chili 1976 nous plonge dans l’atmosphère étouffante de la dictature chilienne, trois ans après le coup d’état du Général Pinochet. </p>
                <p className="small">Enfin le talentueux et glaçant premier film des vénézuéliens Juan Sebastián Vásquez et Alejandro Rojas Border Line, permettra d’ouvrir la réflexion sur le rêve américain, le racisme ordinaire et les abus de pouvoir.</p>
                <p className="small">Nous espérons que cette programmation correspondra à vos attentes et vous permettra d’enrichir vos programmes culturels de langue tout au long de l’année scolaire. </p>
                <p className="small">Belle année cinématographique 2024-2025!</p>
                <p className="small">
                  <strong>Odile MONTAUFRAY</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container films">
        <div className="row">
          <div className="col-lg-12">
            <div className="row no-gutter">
              {data.films.edges.map(({ node: post }) => (
                <div key={post.id} className="col-6 col-sm-6 col-md-4 col-lg-3">
                  <Flip right cascade>
                    <div className="card">
                      <Link to={post.fields.slug}>
                        <Img
                          fluid={post.frontmatter.affiche.childImageSharp.fluid}
                          alt={post.frontmatter.title}
                        />
                        <div className="text">
                          <div className="row no-gutter">
                            <h2 className="card-title text-capitalize col-12 col-sm-5 col-md-7">
                              <Flag code="es" height="11" />
                              {post.frontmatter.title}
                            </h2>
                            <div className="col-sm-7 col-md-5 badges">
                              {post.frontmatter.time != 'NA' ? (
                                <span className="badge badge-pill badge-danger">
                                  {post.frontmatter.time}
                                </span>
                              ) : null}
                              <span className="badge badge-pill badge-warning">
                                {post.frontmatter.classe}
                              </span>
                            </div>
                            <div className="col-lg-12">
                              <small>{post.frontmatter.meta}</small>
                            </div>
                            <p>
                              <div
                                dangerouslySetInnerHTML={{ __html: post.excerpt }}
                              ></div>
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </Flip>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default EspagnolPage;
